<template>
  <v-dialog
    v-model="show"
    max-width="500px"
  >
    <v-card>
      <v-toolbar
        flat
        dense
        color="grey lighten-2"
      >
        <v-toolbar-title>
          Índice Repasses
          <span
            v-if="city"
            class="body-2"
          >
            <i>#{{ city.id }}</i>
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-progress-linear
            v-if="loading"
            color="primary"
            absolute
            bottom
            indeterminate
          />
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text v-if="city">
        <v-row class="pt-5 pb-2">
          <v-col>
            <p class="font-weight-black text-uppercase ma-0">
              {{ city.name }}
              <v-chip
                v-if="city.isCapital"
                color="green"
                label
                outlined
                small
                class="ml-1"
              >
                Capital
              </v-chip>
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              color="success"
              class="mr-2"
              dark
              small
              @click="openDialog()"
            >
              <v-icon
                left
                v-text="'mdi-plus'"
              />
              Adicionar
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-sheet
              elevation="0"
              outlined
            >
              <v-simple-table
                dense
                height="400px"
              >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Tipo
                      </th>
                      <th class="text-left">
                        Ano
                      </th>
                      <th class="text-left">
                        Índice
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-if="cityTypesOfApportionments.length <=0 "
                    >
                      <td
                        colspan="5"
                        class="title"
                      >
                        <i>Nenhum índice cadastrado</i>
                      </td>
                    </tr>
                    <tr
                      v-for="(cityTypeOfApportionment) in cityTypesOfApportionments"
                      :key="cityTypeOfApportionment.id"
                    >
                      <td>
                        <v-chip
                          small
                          label
                          outlined
                          :color="cityTypeOfApportionment.typeOfApportionment.color"
                        >
                          {{ cityTypeOfApportionment.typeOfApportionment.description }}
                        </v-chip>
                      </td>
                      <td>{{ cityTypeOfApportionment.year }}</td>
                      <td>{{ cityTypeOfApportionment.index | number(10, 10) }}</td>
                      <td class="text-right">
                        <v-btn
                          icon
                          small
                          @click="openDialog(cityTypeOfApportionment)"
                        >
                          <v-icon
                            small
                          >
                            mdi-pencil
                          </v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          small
                          :loading="loadingDelete === cityTypeOfApportionment.id"
                          @click="deleteIndex(cityTypeOfApportionment)"
                        >
                          <v-icon
                            small
                          >
                            mdi-delete
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-switch
              v-model="city.hasReserveFund"
              label="Tem fundo de reserva"
              hide-details="auto"
              :false-value="false"
              :true-value="true"
              :loading="loadingUpdateCity"
              :disabled="loadingUpdateCity"
              @change="updateCity()"
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="city.code"
              v-mask="'#####'"
              label="Código"
              filled
              dense
              hide-details="auto"
              :loading="loadingUpdateCity"
              :disabled="loadingUpdateCity"
              @change="updateCity()"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-select
              v-model="city.receiptContributionRangeNumber"
              item-value="id"
              item-text="title"
              label="Faixa de contribuição do recibo"
              hide-details="auto"
              hint="Fixa uma faixa de contribuição a ser usada na geração dos recibos. Deixe em 'automático' para usar a faixa de acordo com o índice."
              persistent-hint
              filled
              :items="contributionsRanges"
              :loading="loading || loadingUpdateCity"
              @change="updateCity()"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <dialog-cities-indexes-fields
      v-model="showDialogIndex"
      :city-id="cityId"
      :index="editIndex"
      @save="load"
    />

  </v-dialog>
</template>

<script>
  import citiesApi from '@/api/cities'
  import contributionsRangesApi from '@/api/contributions-ranges'
  import numberFilter from '@/utils/filters/number'

  export default {

    components: {
      DialogCitiesIndexesFields: () => import('@/components/dialog/cities-indexes/DialogCitiesIndexesFields'),
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      cityId: {
        type: Number,
      },
    },

    data () {
      return {
        loading: false,
        loadingSave: false,
        loadingDelete: false,
        loadingUpdateCity: false,
        showDialogIndex: false,
        editIndex: null,
        city: null,
        cityTypesOfApportionments: [],
      }
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
      contributionsRanges () {
        const ranges = [{ id: null, title: 'Automático' }]
        for (let id = 1; id <= 10; id++) {
          ranges.push({ id, title: id })
        }

        return ranges;
      }
    },

    watch: {
      show (val) {
        if (!val) return

        this.load()
      },
    },

    methods: {

      async load () {
        try {
          this.loading = true

          this.cityTypesOfApportionments = []
          this.city = null

          const [
            responseCity,
            responseCityTypesOfApportionments,
          ] = await Promise.all([
            citiesApi.get(this.cityId),
            citiesApi.listTypesOfApportionments(this.cityId, { order: 'desc' }),
          ])

          this.city = responseCity.data.city
          this.cityTypesOfApportionments = responseCityTypesOfApportionments.data.typesOfApportionments
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      openDialog (index = null) {
        this.editIndex = index
        this.showDialogIndex = true
      },

      async updateCity () {
        try {
          this.loadingUpdateCity = true

          const data = {
            name: this.city.name,
            hasReserveFund: this.city.hasReserveFund,
            code: this.city.code,
            receiptContributionRangeNumber: this.city.receiptContributionRangeNumber,
          }

          await citiesApi.update(this.cityId, data)

          this.$snackbar.show({ color: 'success', message: this.$messages._('update_success') })
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingUpdateCity = false
        }
      },

      async deleteIndex (cityTypeOfApportionment) {
        try {
          this.loadingDelete = cityTypeOfApportionment.id

          await citiesApi.deleteTypeOfApportionment(this.cityId, cityTypeOfApportionment.id)

          this.load()

          this.$snackbar.show({ color: 'success', message: this.$messages._('delete_success') })
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingDelete = null
        }
      },

    },

  }
</script>
